
import { defineComponent } from "vue";
import CompanyLogoLink from "@/components/atoms/link/CompanyLogoLink/CompanyLogoLink.vue";
import PolicyLinkList from "@/components/molecules/element/PolicyLinkList/PolicyLinkList.vue";
import SnsLinkList from "@/components/molecules/element/SnsLinkList/SnsLinkList.vue";
import ToggleRelationListItem from "@/components/molecules/element/ToggleRelationListItem/ToggleRelationListItem.vue";
export default defineComponent({
  components: {
    PolicyLinkList,
    CompanyLogoLink,
    SnsLinkList,
    ToggleRelationListItem
  }
});
