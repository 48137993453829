
import { defineComponent, getCurrentInstance, ref } from "vue";
import { setBodyClassName } from "@/service/setBodyClassName";
import CliveLogoLinkMobile from "@/components/atoms/link/CliveLogoLinkMobile/CliveLogoLinkMobile.vue";
import MobileNavOpenButton from "@/components/atoms/button/MobileNavOpenButton/MobileNavOpenButton.vue";
import MobileNavList from "@/components/molecules/MobileNavList/MobileNavList.vue";

export default defineComponent({
  name: "AppHeader",
  components: {
    CliveLogoLinkMobile,
    MobileNavOpenButton,
    MobileNavList
  },
  props: {
    isFeatureEnabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const toggleState = ref(false);
    const isListVisible = ref(false);
    const navToggle = () => {
      isListVisible.value = false;
      setBodyClassName((toggleState.value = !toggleState.value), "menu-on");
    };
    const listToggle = () => (isListVisible.value = !isListVisible.value);
    const navClose = () =>
      setBodyClassName((toggleState.value = false), "menu-on");

    const internalInstance = getCurrentInstance();
    const emitter =
      internalInstance?.appContext.config.globalProperties.emitter;

    const openModalEvent = () => {
      emitter.emit("onOpenModalEvent", true);
    };

    return {
      toggleState,
      navToggle,
      isListVisible,
      listToggle,
      navClose,
      openModalEvent
    };
  }
});
