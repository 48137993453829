/* eslint-disable @typescript-eslint/no-explicit-any */
import { PRICING_HEADER } from "../mutation-types";

type State = {
  isFixed: boolean;
  isHidden: boolean;
};

const state = {
  isFixed: false,
  isHidden: true
};

const getters = {
  isHeaderFixed: (state: State) => {
    return state.isFixed;
  },
  isHeaderHidden: (state: State) => {
    return state.isHidden;
  }
};

const actions = {
  setFixed({ commit }: any, isHeaderFixed: boolean) {
    commit(PRICING_HEADER.SET_FIXED, isHeaderFixed);
  },
  setHidden({ commit }: any, isHeaderHidden: boolean) {
    commit(PRICING_HEADER.SET_HIDE, isHeaderHidden);
  }
};

const mutations = {
  [PRICING_HEADER.SET_FIXED](state: State, isHeaderFixed: boolean) {
    state.isFixed = isHeaderFixed;
  },
  [PRICING_HEADER.SET_HIDE](state: State, isHeaderHidden: boolean) {
    state.isHidden = isHeaderHidden;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
