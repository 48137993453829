
import { defineComponent } from "vue";
import CompanyLogoLink from "@/components/atoms/link/CompanyLogoLink/CompanyLogoLink.vue";
import PolicyLinkListMobile from "@/components/molecules/element/PolicyLinkList/PolicyLinkListMobile.vue";
import SnsLinkListMobile from "@/components/molecules/element/SnsLinkList/SnsLinkListMobile.vue";
import ToggleRelationListItemMobile from "@/components/molecules/element/ToggleRelationListItem/ToggleRelationListItemMobile.vue";
export default defineComponent({
  components: {
    PolicyLinkListMobile,
    CompanyLogoLink,
    SnsLinkListMobile,
    ToggleRelationListItemMobile
  }
});
