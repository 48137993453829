
import CompanyList from "@/components/molecules/element/CompanyList/CompanyList.vue";
import ToggleListTitleMobile from "@/components/molecules/element/ToggleListTitle/ToggleListTitleMobile.vue";
import { defineComponent, ref } from "vue";
export default defineComponent({
  components: {
    CompanyList,
    ToggleListTitleMobile
  },
  setup() {
    const isVisible = ref(false);
    const toggleList = () => {
      isVisible.value = !isVisible.value;
    };
    return {
      isVisible,
      toggleList
    };
  }
});
