import { ref } from "vue";
import { useStore } from "vuex";

export const checkViewport = () => {
  const store = useStore();
  const currentSize = ref();
  store.dispatch("deviceCheck/loadSizeCheck");
  window.addEventListener("resize", () => {
    currentSize.value = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    store.dispatch("deviceCheck/currentSizeCheck", currentSize.value);
    store.dispatch("deviceCheck/largeScreenCheck", currentSize.value);
  });
  return {};
};
