import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f553bef0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxToggleButtonMobile = _resolveComponent("BoxToggleButtonMobile")!
  const _component_RelationListMobile = _resolveComponent("RelationListMobile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BoxToggleButtonMobile, {
      isActive: _ctx.isActive,
      onClick: _ctx.toggleList
    }, null, 8, ["isActive", "onClick"]),
    _withDirectives(_createVNode(_component_RelationListMobile, {
      "aria-hidden": !_ctx.isActive
    }, null, 8, ["aria-hidden"]), [
      [_vShow, _ctx.isActive]
    ])
  ]))
}