
import { defineComponent } from "vue";
import { POLICY_LIST as list } from "@/shared/policyList";
// import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import ToggleListItemMobile from "@/components/molecules/element/ToggleListItem/ToggleListItemMobile.vue";
export default defineComponent({
  name: "PolicyLinkList",
  components: {
    AppLink,
    // AppRouterLink,
    ToggleListItemMobile
  },
  setup() {
    const items = list;
    return {
      items
    };
  }
});
