
// import DownloadIcon from "@/components/atoms/icon/DownloadIcon/DownloadIcon.vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import { defineComponent } from "vue";
import { COMPANY_LIST as list } from "@/shared/companyList";
export default defineComponent({
  components: { AppLink },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["clickEvent"],
  setup() {
    const items = list;
    const isLastItem = (index: number, maxIndex: number) =>
      index + 1 === maxIndex;
    return {
      items,
      isLastItem
    };
  }
});
