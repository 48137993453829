<template>
  <svg
    width="79"
    height="16"
    viewBox="0 0 79 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42957 13.2627H8.45217V15.5879H0V0.796631H2.42957V13.2627Z"
      fill="#BABBBC"
    />
    <path
      d="M12.0313 3.00347C11.7426 3.29216 11.4 3.43651 11.007 3.43651C10.6122 3.43651 10.2713 3.29216 9.9826 3.00347C9.69391 2.71477 9.54956 2.3739 9.54956 1.97912C9.54956 1.58434 9.69043 1.24347 9.97217 0.954772C10.2539 0.666076 10.5983 0.521729 11.007 0.521729C11.4156 0.521729 11.76 0.666076 12.0417 0.954772C12.3235 1.24347 12.4643 1.58608 12.4643 1.97912C12.4643 2.3739 12.32 2.71477 12.0313 3.00347ZM9.86608 15.5861V5.0226H12.1478V15.5878H9.86608V15.5861Z"
      fill="#BABBBC"
    />
    <path
      d="M21.8383 5.02271H24.3322L20.2122 15.5879H17.5705L13.4487 5.02271H15.9426L18.9009 13.0749L21.8383 5.02271Z"
      fill="#BABBBC"
    />
    <path
      d="M26.9843 11.2765C27.153 12.0799 27.5408 12.6956 28.146 13.1252C28.7512 13.5547 29.4904 13.7704 30.3652 13.7704C31.5773 13.7704 32.4852 13.3339 33.0904 12.4608L34.9704 13.5599C33.9269 15.0956 32.386 15.8626 30.3426 15.8626C28.6243 15.8626 27.2365 15.3373 26.1791 14.2886C25.1234 13.2399 24.5947 11.9113 24.5947 10.306C24.5947 8.72864 25.1165 7.40864 26.1582 6.34429C27.1999 5.27995 28.5391 4.74951 30.1721 4.74951C31.7217 4.74951 32.993 5.29212 33.986 6.37734C34.9791 7.46256 35.4765 8.77908 35.4765 10.3286C35.4765 10.5686 35.4486 10.8852 35.3912 11.2799H26.9843V11.2765ZM26.9634 9.41734H33.1756C33.0208 8.55821 32.6643 7.90951 32.1078 7.47299C31.5512 7.03647 30.8991 6.81734 30.153 6.81734C29.3078 6.81734 28.6034 7.05038 28.0399 7.51473C27.4782 7.98082 27.1182 8.61386 26.9634 9.41734Z"
      fill="#BABBBC"
    />
    <path
      d="M50.3339 15.8609C48.1078 15.8609 46.2626 15.1253 44.7982 13.6522C43.3339 12.1809 42.6 10.3601 42.6 8.18962C42.6 6.02092 43.3321 4.20005 44.7982 2.72701C46.2626 1.2557 48.1078 0.518311 50.3339 0.518311C51.6713 0.518311 52.9078 0.834832 54.0417 1.46961C55.1756 2.10266 56.0591 2.96353 56.6939 4.04701L54.5808 5.27309C54.1861 4.54092 53.6122 3.96005 52.8591 3.53048C52.1061 3.10092 51.2643 2.88527 50.3339 2.88527C48.7565 2.88527 47.4782 3.38614 46.4991 4.38614C45.52 5.38614 45.0313 6.65396 45.0313 8.18962C45.0313 9.72527 45.52 10.9931 46.4991 11.9931C47.4782 12.9931 48.7565 13.494 50.3339 13.494C51.2643 13.494 52.1095 13.28 52.8695 12.8487C53.6295 12.4192 54.2 11.8383 54.5808 11.1061L56.6939 12.3114C56.0748 13.3966 55.1965 14.2592 54.0626 14.8992C52.9287 15.5409 51.6852 15.8609 50.3339 15.8609Z"
      fill="#BABBBC"
    />
    <path
      d="M66.8157 5.02256H69.0974V15.5878H66.8157V14.066C65.9566 15.2643 64.7235 15.8626 63.1183 15.8626C61.6679 15.8626 60.4279 15.3234 59.4 14.2469C58.3722 13.1686 57.8574 11.8556 57.8574 10.306C57.8574 8.74256 58.3722 7.42603 59.4 6.35473C60.4279 5.28343 61.6679 4.74951 63.1183 4.74951C64.7235 4.74951 65.9566 5.34082 66.8157 6.52516V5.02256ZM61.0887 12.7234C61.7235 13.3652 62.5183 13.6852 63.4766 13.6852C64.4348 13.6852 65.2296 13.3652 65.8644 12.7234C66.4992 12.0834 66.8157 11.2765 66.8157 10.3043C66.8157 9.33212 66.4992 8.5269 65.8644 7.88516C65.2313 7.24516 64.4348 6.92343 63.4766 6.92343C62.5183 6.92343 61.7235 7.24342 61.0887 7.88516C60.4539 8.5269 60.1374 9.33212 60.1374 10.3043C60.1374 11.2765 60.4539 12.0834 61.0887 12.7234Z"
      fill="#BABBBC"
    />
    <path
      d="M71.6382 15.5862V0.161865H73.9199V15.5862H71.6382Z"
      fill="#BABBBC"
    />
    <path d="M76.4382 15.5862V0.161865H78.72V15.5862H76.4382Z" fill="#BABBBC" />
  </svg>
</template>
