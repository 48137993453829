import { useStore } from "vuex";
import { setBodyClassName } from "@/service/setBodyClassName";

export const checkScroll = () => {
  const store = useStore();
  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const isScrollOver = scrollY > 0;
    store.dispatch("pageCheck/scrollingCheck", isScrollOver);
    store.dispatch("pageCheck/pageOffsetCheck", scrollY);
    setBodyClassName(isScrollOver, "scrollover");
  });
  return {};
};
