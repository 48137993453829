<template>
  <AppLink href="/" class="link" hiddenText="kakao i connect live">
    <template v-slot:linkSlot>
      <CliveLogo :color="activeColor" />
    </template>
  </AppLink>
</template>

<script>
import { computed, defineComponent } from "vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import CliveLogo from "@/components/atoms/logo/CliveLogo/CliveLogo.vue";
export default defineComponent({
  components: {
    AppLink,
    CliveLogo
  },
  props: {
    isActive: {
      type: Boolean
    },
    isMobileSize: {
      type: Boolean
    }
  },
  setup(props) {
    const activeColor = computed(() => {
      if (!props.isMobileSize) {
        return "#000";
      } else {
        return props.isActive ? "#fff" : "#000";
      }
    });
    return {
      activeColor
    };
  }
});
</script>

<style lang="scss" scoped>
.link {
  display: block;
  line-height: 1;
}
svg {
  width: 90px;
  height: 46px;
}
</style>
