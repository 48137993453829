import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleListTitleMobile = _resolveComponent("ToggleListTitleMobile")!
  const _component_CompanyList = _resolveComponent("CompanyList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ToggleListTitleMobile, {
      isVisible: _ctx.isVisible,
      onClick: _withModifiers(_ctx.toggleList, ["prevent"])
    }, null, 8, ["isVisible", "onClick"]),
    _createVNode(_component_CompanyList, {
      isVisible: _ctx.isVisible,
      onClickEvent: _ctx.toggleList
    }, null, 8, ["isVisible", "onClickEvent"])
  ], 64))
}