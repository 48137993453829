<template>
  <svg
    width="88"
    height="16"
    viewBox="0 0 88 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42957 13.2627H8.45217V15.5879H0V0.796631H2.42957V13.2627Z"
      fill="#BABBBC"
    />
    <path
      d="M12.0313 3.00521C11.7426 3.2939 11.4 3.43825 11.007 3.43825C10.6122 3.43825 10.2713 3.2939 9.9826 3.00521C9.69391 2.71651 9.54956 2.37564 9.54956 1.98086C9.54956 1.58608 9.69043 1.24521 9.97217 0.956511C10.2522 0.666076 10.5983 0.521729 11.007 0.521729C11.4156 0.521729 11.76 0.666076 12.0417 0.954772C12.3235 1.24347 12.4643 1.58608 12.4643 1.97912C12.4643 2.3739 12.32 2.71651 12.0313 3.00521ZM9.86608 15.5878V5.0226H12.1478V15.5878H9.86608Z"
      fill="#BABBBC"
    />
    <path
      d="M21.8383 5.02271H24.3322L20.2122 15.5879H17.5705L13.4487 5.02271H15.9426L18.9009 13.0749L21.8383 5.02271Z"
      fill="#BABBBC"
    />
    <path
      d="M26.9844 11.2765C27.1531 12.0799 27.5409 12.6956 28.1461 13.1252C28.7513 13.5547 29.4905 13.7704 30.3653 13.7704C31.5757 13.7704 32.4853 13.3339 33.0905 12.4608L34.9705 13.5599C33.927 15.0956 32.3861 15.8626 30.3426 15.8626C28.6244 15.8626 27.2366 15.3373 26.1809 14.2886C25.1235 13.2399 24.5966 11.9113 24.5966 10.306C24.5966 8.72864 25.1183 7.40864 26.16 6.34429C27.2018 5.27995 28.5409 4.74951 30.174 4.74951C31.7235 4.74951 32.9948 5.29212 33.9879 6.37734C34.9809 7.46255 35.4783 8.77908 35.4783 10.3286C35.4783 10.5686 35.4505 10.8852 35.3931 11.2799H26.9844V11.2765ZM26.9635 9.41734H33.1757C33.0209 8.55821 32.6644 7.90951 32.1079 7.47299C31.5513 7.03647 30.8992 6.81734 30.1531 6.81734C29.3079 6.81734 28.6035 7.05038 28.04 7.51473C27.4783 7.98082 27.1183 8.61386 26.9635 9.41734Z"
      fill="#BABBBC"
    />
    <path
      d="M50.3339 15.8626C48.1078 15.8626 46.2626 15.1269 44.7982 13.6539C43.3321 12.1826 42.6 10.3617 42.6 8.19129C42.6 6.0226 43.3321 4.20173 44.7982 2.72868C46.2626 1.25738 48.1078 0.521729 50.3339 0.521729C51.6713 0.521729 52.9078 0.83825 54.0417 1.47303C55.1756 2.10608 56.0591 2.96695 56.6939 4.05042L54.5808 5.27651C54.1861 4.54434 53.6122 3.96347 52.8591 3.5339C52.1061 3.10434 51.2643 2.88868 50.3339 2.88868C48.7565 2.88868 47.4782 3.38955 46.4991 4.38955C45.52 5.38955 45.0313 6.65738 45.0313 8.19303C45.0313 9.72868 45.52 10.9965 46.4991 11.9965C47.4782 12.9965 48.7565 13.4974 50.3339 13.4974C51.2643 13.4974 52.1095 13.2835 52.8695 12.8522C53.6295 12.4226 54.2 11.8417 54.5808 11.1096L56.6939 12.3148C56.073 13.4 55.1965 14.2626 54.0626 14.9026C52.9287 15.5426 51.6852 15.8626 50.3339 15.8626Z"
      fill="#BABBBC"
    />
    <path
      d="M66.8138 5.02256H69.0956V15.5878H66.8138V14.066C65.9547 15.2643 64.7217 15.8626 63.1165 15.8626C61.666 15.8626 60.426 15.3234 59.3982 14.2469C58.3704 13.1686 57.8556 11.8556 57.8556 10.306C57.8556 8.74256 58.3704 7.42603 59.3982 6.35473C60.426 5.28343 61.666 4.74951 63.1165 4.74951C64.7217 4.74951 65.9547 5.34082 66.8138 6.52516V5.02256ZM61.0886 12.7252C61.7217 13.3669 62.5182 13.6869 63.4765 13.6869C64.4347 13.6869 65.2295 13.3669 65.8643 12.7252C66.4973 12.0852 66.8156 11.2782 66.8156 10.306C66.8156 9.33386 66.4991 8.52864 65.8643 7.8869C65.2295 7.2469 64.4347 6.92516 63.4765 6.92516C62.5182 6.92516 61.7234 7.24516 61.0886 7.8869C60.4556 8.52864 60.1373 9.33386 60.1373 10.306C60.1373 11.2782 60.4539 12.0834 61.0886 12.7252Z"
      fill="#BABBBC"
    />
    <path
      d="M73.6522 7.92346C73.6522 8.27128 73.847 8.54606 74.2383 8.7478C74.6279 8.94954 75.0974 9.13389 75.6435 9.30259C76.1896 9.47128 76.7496 9.64346 77.3235 9.82085C77.8974 9.99824 78.3757 10.3252 78.76 10.8017C79.1444 11.2782 79.3374 11.8817 79.3374 12.6087C79.3374 13.6226 78.9461 14.4191 78.1653 14.9965C77.3827 15.5739 76.4 15.8626 75.2174 15.8626C74.174 15.8626 73.28 15.6435 72.534 15.2069C71.7879 14.7704 71.2453 14.1652 70.9061 13.3895L72.8713 12.2487C73.2383 13.2626 74.0192 13.7704 75.2174 13.7704C76.414 13.7704 77.014 13.3756 77.014 12.5861C77.014 12.2487 76.8209 11.9739 76.4331 11.7617C76.0453 11.5513 75.5792 11.36 75.0331 11.193C74.487 11.0243 73.927 10.8522 73.3531 10.6748C72.7792 10.4991 72.2992 10.1808 71.9113 9.72345C71.5235 9.26606 71.3305 8.68519 71.3305 7.98085C71.3305 7.00867 71.7009 6.2278 72.44 5.63476C73.1792 5.04345 74.0992 4.7478 75.1983 4.7478C76.0713 4.7478 76.8505 4.94085 77.5322 5.32867C78.2157 5.7165 78.7409 6.25563 79.1061 6.94432L77.1826 8.02259C76.8157 7.21911 76.1548 6.81737 75.1966 6.81737C74.76 6.81737 74.3931 6.91128 74.0974 7.09911C73.8 7.28867 73.6522 7.56346 73.6522 7.92346Z"
      fill="#BABBBC"
    />
    <path
      d="M87.1947 7.22097H84.5739V12.2923C84.5739 12.7288 84.673 13.0418 84.8695 13.2331C85.066 13.4227 85.3582 13.5288 85.746 13.5497C86.1339 13.5705 86.6156 13.5671 87.193 13.5392V15.5897C85.4469 15.8001 84.193 15.6488 83.4313 15.1358C82.6695 14.6227 82.2904 13.6749 82.2904 12.294V7.22097H80.3478V5.02271H82.2921V2.74097L84.5739 2.06445V5.02271H87.1947V7.22097Z"
      fill="#BABBBC"
    />
  </svg>
</template>
