<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="14.364"
      width="20"
      height="2"
      transform="rotate(-45 0 14.364)"
      fill="white"
    />
    <rect
      x="1.41504"
      y="0.221802"
      width="20"
      height="2"
      transform="rotate(45 1.41504 0.221802)"
      fill="white"
    />
  </svg>
</template>
