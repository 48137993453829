import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "screen-out"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "link",
    to: _ctx.url,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
      (_ctx.hiddenText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.hiddenText), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "linkSlot")
    ]),
    _: 3
  }, 8, ["to", "title"]))
}