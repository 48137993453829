
import { defineComponent } from "vue";
import AppButton from "@/components/atoms/button/AppButton/AppButton.vue";
export default defineComponent({
  components: { AppButton },
  props: {
    isActive: {
      type: Boolean
    }
  }
});
