<template>
  <svg
    width="155"
    height="16"
    viewBox="0 0 155 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42957 13.2625H8.45217V15.5877H0V0.796387H2.42957V13.2625Z"
      fill="#BABBBC"
    />
    <path
      d="M12.0313 3.00322C11.7426 3.29192 11.4 3.43627 11.007 3.43627C10.6122 3.43627 10.2713 3.29192 9.9826 3.00322C9.69391 2.71453 9.54956 2.37366 9.54956 1.97888C9.54956 1.58409 9.69043 1.24322 9.97217 0.954528C10.2539 0.665832 10.5983 0.521484 11.007 0.521484C11.4156 0.521484 11.76 0.665832 12.0417 0.954528C12.3235 1.24322 12.4643 1.58583 12.4643 1.97888C12.4643 2.37366 12.32 2.71627 12.0313 3.00322ZM9.86608 15.5876V5.02235H12.1478V15.5876H9.86608Z"
      fill="#BABBBC"
    />
    <path
      d="M21.8383 5.02246H24.3322L20.2122 15.5877H17.5705L13.4487 5.02246H15.9426L18.9009 13.0746L21.8383 5.02246Z"
      fill="#BABBBC"
    />
    <path
      d="M26.9844 11.2765C27.1531 12.0799 27.5409 12.6956 28.1461 13.1252C28.7513 13.5547 29.4905 13.7704 30.3653 13.7704C31.5757 13.7704 32.4853 13.3339 33.0905 12.4608L34.9705 13.5599C33.927 15.0956 32.3861 15.8626 30.3426 15.8626C28.6244 15.8626 27.2366 15.3373 26.1809 14.2886C25.1235 13.2399 24.5966 11.9113 24.5966 10.306C24.5966 8.72864 25.1183 7.40864 26.16 6.34429C27.2018 5.27995 28.5409 4.74951 30.174 4.74951C31.7235 4.74951 32.9948 5.29212 33.9879 6.37734C34.9809 7.46256 35.4783 8.77908 35.4783 10.3286C35.4783 10.5686 35.4505 10.8852 35.3931 11.2799H26.9844V11.2765ZM26.9635 9.41734H33.1757C33.0209 8.55821 32.6644 7.90951 32.1079 7.47299C31.5513 7.03647 30.8992 6.81734 30.1531 6.81734C29.3079 6.81734 28.6035 7.05038 28.04 7.51473C27.4783 7.98082 27.1183 8.61386 26.9635 9.41734Z"
      fill="#BABBBC"
    />
    <path
      d="M50.3339 15.8624C48.1078 15.8624 46.2626 15.1267 44.7982 13.6537C43.3321 12.1824 42.6 10.3615 42.6 8.19108C42.6 6.02238 43.3321 4.20151 44.7982 2.72847C46.2626 1.25717 48.1078 0.519775 50.3339 0.519775C51.6713 0.519775 52.9078 0.836297 54.0417 1.47108C55.1756 2.10412 56.0591 2.96499 56.6939 4.04847L54.5808 5.27456C54.1861 4.54238 53.6122 3.96151 52.8591 3.53195C52.1061 3.10238 51.2643 2.88673 50.3339 2.88673C48.7565 2.88673 47.4782 3.3876 46.4991 4.3876C45.52 5.3876 45.0313 6.65543 45.0313 8.19108C45.0313 9.72673 45.52 10.9946 46.4991 11.9946C47.4782 12.9946 48.7565 13.4954 50.3339 13.4954C51.2643 13.4954 52.1095 13.2815 52.8695 12.8502C53.6295 12.4206 54.2 11.8398 54.5808 11.1076L56.6939 12.3128C56.073 13.398 55.1965 14.2606 54.0626 14.9006C52.9287 15.5424 51.6852 15.8624 50.3339 15.8624Z"
      fill="#BABBBC"
    />
    <path
      d="M63.4226 15.8626C61.873 15.8626 60.5565 15.3269 59.4713 14.2573C58.3861 13.186 57.8452 11.8695 57.8452 10.306C57.8452 8.74256 58.3878 7.42603 59.4713 6.35473C60.5565 5.28342 61.873 4.74951 63.4226 4.74951C64.9861 4.74951 66.3061 5.28516 67.3843 6.35473C68.4626 7.42603 69 8.74256 69 10.306C69 11.8695 68.4609 13.1878 67.3843 14.2573C66.3078 15.3269 64.9878 15.8626 63.4226 15.8626ZM61.0783 12.6921C61.7113 13.3269 62.4939 13.6434 63.4243 13.6434C64.3548 13.6434 65.1357 13.3269 65.7704 12.6921C66.4035 12.0591 66.7217 11.2626 66.7217 10.3043C66.7217 9.34603 66.4052 8.55125 65.7704 7.91647C65.1374 7.28342 64.3548 6.96516 63.4243 6.96516C62.4939 6.96516 61.713 7.28169 61.0783 7.91647C60.4452 8.55125 60.127 9.34603 60.127 10.3043C60.127 11.2626 60.4435 12.0591 61.0783 12.6921Z"
      fill="#BABBBC"
    />
    <path
      d="M76.4887 4.74756C77.7009 4.74756 78.6783 5.13538 79.4261 5.9093C80.1722 6.68495 80.5461 7.74756 80.5461 9.1006V15.5876H78.2644V9.33191C78.2644 8.54234 78.054 7.93712 77.6314 7.51452C77.2087 7.09191 76.6314 6.87973 75.8992 6.87973C75.0957 6.87973 74.4487 7.13017 73.9548 7.6293C73.4609 8.13017 73.2157 8.89365 73.2157 9.92147V15.5841H70.934V5.02234H73.2157V6.37538C73.9027 5.29017 74.9948 4.74756 76.4887 4.74756Z"
      fill="#BABBBC"
    />
    <path
      d="M88.2556 2.71815C86.593 2.56337 85.7617 3.26076 85.7617 4.81032V5.0225H88.2556V7.22076H85.7617V15.5877H83.48V7.21902H81.8748V5.0225H83.48V4.81032C83.48 3.35989 83.8852 2.25728 84.6956 1.50424C85.5061 0.751192 86.6921 0.422496 88.2556 0.521627V2.71815Z"
      fill="#BABBBC"
    />
    <path
      d="M91.2921 11.2765C91.4608 12.0799 91.8486 12.6956 92.4539 13.1252C93.0591 13.5547 93.7982 13.7704 94.673 13.7704C95.8834 13.7704 96.793 13.3339 97.3982 12.4608L99.2782 13.5599C98.2347 15.0956 96.6939 15.8626 94.6504 15.8626C92.9321 15.8626 91.5443 15.3373 90.4886 14.2886C89.4313 13.2399 88.9043 11.9113 88.9043 10.306C88.9043 8.72864 89.426 7.40864 90.4678 6.34429C91.5095 5.27995 92.8486 4.74951 94.4817 4.74951C96.0313 4.74951 97.3026 5.29212 98.2956 6.37734C99.2886 7.46256 99.786 8.77908 99.786 10.3286C99.786 10.5686 99.7582 10.8852 99.7008 11.2799H91.2921V11.2765ZM91.2713 9.41734H97.4834C97.3286 8.55821 96.9721 7.90951 96.4156 7.47299C95.8591 7.03647 95.2069 6.81734 94.4608 6.81734C93.6156 6.81734 92.9113 7.05038 92.3478 7.51473C91.7843 7.98082 91.426 8.61386 91.2713 9.41734Z"
      fill="#BABBBC"
    />
    <path
      d="M103.97 6.79627C104.548 5.48671 105.633 4.83105 107.224 4.83105V7.3041C106.351 7.24845 105.59 7.4554 104.943 7.92671C104.294 8.39801 103.97 9.17714 103.97 10.2606V15.5858H101.687V5.02236H103.969V6.79627H103.97Z"
      fill="#BABBBC"
    />
    <path
      d="M110.423 11.2765C110.591 12.0799 110.979 12.6956 111.584 13.1252C112.19 13.5547 112.929 13.7704 113.803 13.7704C115.014 13.7704 115.923 13.3339 116.529 12.4608L118.409 13.5599C117.365 15.0956 115.824 15.8626 113.781 15.8626C112.063 15.8626 110.675 15.3373 109.619 14.2886C108.562 13.2399 108.035 11.9113 108.035 10.306C108.035 8.72864 108.557 7.40864 109.598 6.34429C110.64 5.27995 111.979 4.74951 113.612 4.74951C115.162 4.74951 116.433 5.29212 117.426 6.37734C118.419 7.46256 118.917 8.77908 118.917 10.3286C118.917 10.5686 118.889 10.8852 118.831 11.2799H110.423V11.2765ZM110.402 9.41734H116.614C116.459 8.55821 116.103 7.90951 115.546 7.47299C114.99 7.03647 114.337 6.81734 113.591 6.81734C112.746 6.81734 112.042 7.05038 111.478 7.51473C110.915 7.98082 110.557 8.61386 110.402 9.41734Z"
      fill="#BABBBC"
    />
    <path
      d="M126.376 4.74756C127.588 4.74756 128.565 5.13538 129.313 5.9093C130.059 6.68495 130.433 7.74756 130.433 9.1006V15.5876H128.151V9.33191C128.151 8.54234 127.941 7.93712 127.518 7.51452C127.096 7.09191 126.518 6.87973 125.786 6.87973C124.983 6.87973 124.336 7.13017 123.842 7.6293C123.348 8.13017 123.103 8.89365 123.103 9.92147V15.5841H120.821V5.02234H123.103V6.37538C123.791 5.29017 124.882 4.74756 126.376 4.74756Z"
      fill="#BABBBC"
    />
    <path
      d="M137.805 15.8626C136.214 15.8626 134.885 15.3269 133.823 14.2573C132.758 13.186 132.228 11.8695 132.228 10.306C132.228 8.72864 132.76 7.40864 133.823 6.34429C134.885 5.27995 136.214 4.74951 137.805 4.74951C138.833 4.74951 139.77 4.99647 140.616 5.48864C141.461 5.98256 142.094 6.64343 142.518 7.47473L140.553 8.6156C140.313 8.10777 139.951 7.71125 139.464 7.42255C138.979 7.13386 138.419 6.98951 137.784 6.98951C136.854 6.98951 136.076 7.30603 135.449 7.94082C134.821 8.57386 134.508 9.36342 134.508 10.3078C134.508 11.2521 134.821 12.0399 135.449 12.6747C136.075 13.3078 136.854 13.626 137.784 13.626C138.403 13.626 138.963 13.4782 139.464 13.1826C139.963 12.8869 140.341 12.4921 140.595 11.9999L142.56 13.1199C142.11 13.9652 141.461 14.6347 140.616 15.1269C139.77 15.6156 138.833 15.8626 137.805 15.8626Z"
      fill="#BABBBC"
    />
    <path
      d="M145.837 11.2765C146.005 12.0799 146.393 12.6956 146.998 13.1252C147.604 13.5547 148.343 13.7704 149.217 13.7704C150.428 13.7704 151.337 13.3339 151.943 12.4608L153.823 13.5599C152.779 15.0956 151.238 15.8626 149.195 15.8626C147.477 15.8626 146.089 15.3373 145.033 14.2886C143.976 13.2399 143.449 11.9113 143.449 10.306C143.449 8.72864 143.97 7.40864 145.012 6.34429C146.054 5.27995 147.393 4.74951 149.026 4.74951C150.576 4.74951 151.847 5.29212 152.84 6.37734C153.833 7.46256 154.33 8.77908 154.33 10.3286C154.33 10.5686 154.303 10.8852 154.245 11.2799H145.837V11.2765ZM145.816 9.41734H152.028C151.873 8.55821 151.517 7.90951 150.96 7.47299C150.404 7.03647 149.751 6.81734 149.005 6.81734C148.16 6.81734 147.456 7.05038 146.892 7.51473C146.329 7.98082 145.97 8.61386 145.816 9.41734Z"
      fill="#BABBBC"
    />
  </svg>
</template>
