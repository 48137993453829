import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, setBlockTracking as _setBlockTracking, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f118580c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_ModalLayer = _resolveComponent("ModalLayer")!
  const _component_AppHeaderMobile = _resolveComponent("AppHeaderMobile")!
  const _component_AppFooterMobile = _resolveComponent("AppFooterMobile")!
  const _component_ModalLayerMobile = _resolveComponent("ModalLayerMobile")!

  return (!_ctx.isMobileAgent)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.isNotfond)
          ? (_openBlock(), _createBlock(_component_router_view, {
              key: 0,
              name: "notfound"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_AppHeader, { isFeatureEnabled: _ctx.pageFeatureInfo }, null, 8, ["isFeatureEnabled"]),
              _createElementVNode("main", _hoisted_1, [
                _createVNode(_component_router_view)
              ]),
              _cache[0] || (
                _setBlockTracking(-1),
                _cache[0] = _createVNode(_component_AppFooter),
                _setBlockTracking(1),
                _cache[0]
              ),
              _cache[1] || (
                _setBlockTracking(-1),
                _cache[1] = _createVNode(_component_ModalLayer),
                _setBlockTracking(1),
                _cache[1]
              )
            ], 64))
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.isNotfond)
          ? (_openBlock(), _createBlock(_component_router_view, {
              key: 0,
              name: "notfoundMobile"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_AppHeaderMobile, { isFeatureEnabled: _ctx.pageFeatureInfo }, null, 8, ["isFeatureEnabled"]),
              _createElementVNode("main", null, [
                _createVNode(_component_router_view, { name: "mobile" })
              ]),
              _cache[2] || (
                _setBlockTracking(-1),
                _cache[2] = _createVNode(_component_AppFooterMobile),
                _setBlockTracking(1),
                _cache[2]
              ),
              _cache[3] || (
                _setBlockTracking(-1),
                _cache[3] = _createVNode(_component_ModalLayerMobile),
                _setBlockTracking(1),
                _cache[3]
              )
            ], 64))
      ], 64))
}