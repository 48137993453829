
import { defineComponent, ref } from "vue";
import BoxToggleButton from "@/components/atoms/button/BoxToggleButton/BoxToggleButton.vue";
import RelationList from "../RelationList/RelationList.vue";
export default defineComponent({
  components: { BoxToggleButton, RelationList },
  setup() {
    const isActive = ref(false);
    const toggleList = () => {
      isActive.value = !isActive.value;
    };
    return {
      isActive,
      toggleList
    };
  }
});
