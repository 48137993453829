const POLICY_LIST = [
  {
    text: "개인정보처리방침",
    // url: "/policy/privacy"
    url: "https://policy.kakaoi.ai/service_privacy"
  },
  {
    text: "서비스이용약관",
    // url: "/policy/terms"
    url: "https://connectlive.kakaoi.ai/policy/terms"
  },
  {
    text: "대표메일",
    url: "mailto:contact@kakaoenterprise.com"
  }
];

export { POLICY_LIST };
