
import { defineComponent, getCurrentInstance } from "vue";
import { trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import CliveLogoLink from "@/components/atoms/link/CliveLogoLink/CliveLogoLink.vue";
import GnbList from "@/components/molecules/GnbList/GnbList.vue";
import ConsoleLink from "@/components/atoms/link/ConsoleLink/ConsoleLink.vue";

export default defineComponent({
  name: "AppHeader",
  components: {
    CliveLogoLink,
    GnbList,
    ConsoleLink
  },
  props: {
    isFeatureEnabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const emitter =
      internalInstance?.appContext.config.globalProperties.emitter;

    const openModalEvent = () => {
      emitter.emit("onOpenModalEvent", true);
    };

    const trackEventConsole = () => {
      gtagEvents("console");
      // gtagEvents("main-무료로 시작하기");
      trackerSetClick("login-console");
    };
    return {
      trackEventConsole,
      openModalEvent
    };
  }
});
