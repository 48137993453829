/* eslint-disable @typescript-eslint/no-explicit-any */
import { PAGING } from "../mutation-types";

type State = {
  currentPage: string;
  isFeatureEnabled: boolean;
  isScrolling: boolean;
  currentYPageOffset: number;
};

const state = {
  currentPage: "",
  isFeatureEnabled: false,
  isScrolling: false,
  currentYPageOffset: 0
};

const getters = {
  pageFeatureInfo: (state: State) => {
    return state.isFeatureEnabled;
  },
  pageIsScrolling: (state: State) => {
    return state.isScrolling;
  },
  pageYOffsetInfo: (state: State) => {
    return state.currentYPageOffset;
  }
};

const actions = {
  featureCheck({ commit }: any, isFeatureEnabled: boolean) {
    commit(PAGING.FEATURE_ENABLED, isFeatureEnabled);
  },
  scrollingCheck({ commit }: any, isScrolling: boolean) {
    commit(PAGING.CURRENT_SCROLLING, isScrolling);
  },
  pageOffsetCheck({ commit }: any, currentYPageOffset: number) {
    commit(PAGING.CURRENT_OFFSET, currentYPageOffset);
  }
};

const mutations = {
  [PAGING.FEATURE_ENABLED](state: State, isFeatureEnabled: boolean) {
    state.isFeatureEnabled = isFeatureEnabled;
  },
  [PAGING.CURRENT_SCROLLING](state: State, isScrolling: boolean) {
    state.isScrolling = isScrolling;
  },
  [PAGING.CURRENT_OFFSET](state: State, currentYPageOffset: number) {
    state.currentYPageOffset = currentYPageOffset;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
