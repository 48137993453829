import { useStore } from "vuex";
import { MEDIA_VIEWPORT } from "@/shared/mediaViewport";

export const checkMobile = () => {
  const store = useStore();
  const isMobile =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    ((navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)) &&
      window.innerWidth < MEDIA_VIEWPORT.smallScreen);
  return store.dispatch("deviceCheck/agentCheck", isMobile);
};
