
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    AppLink
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  }
});
