import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLink = _resolveComponent("AppLink")!

  return (_openBlock(), _createBlock(_component_AppLink, {
    class: _normalizeClass(["link", { 'is-active': _ctx.isVisible }]),
    href: "#",
    role: "button",
    text: "회사소개",
    hiddenText: _ctx.isVisible ? '메뉴 닫기' : '메뉴 열기'
  }, null, 8, ["class", "hiddenText"]))
}