import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02462368"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target", "title", "rel"]
const _hoisted_2 = {
  key: 0,
  class: "screen-out"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "link",
    href: _ctx.href,
    target: _ctx.target,
    title: _ctx.title,
    rel: _ctx.setRel,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickEvent')))
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (_ctx.hiddenText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.hiddenText), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "linkSlot", {}, undefined, true)
  ], 8, _hoisted_1))
}