import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavTitleLivecallIcon = _resolveComponent("NavTitleLivecallIcon")!
  const _component_NavTitleLivecastIcon = _resolveComponent("NavTitleLivecastIcon")!
  const _component_NavTitleLiveconfIcon = _resolveComponent("NavTitleLiveconfIcon")!

  return (_ctx.iconType === 'livecall')
    ? (_openBlock(), _createBlock(_component_NavTitleLivecallIcon, { key: 0 }))
    : (_ctx.iconType === 'livecast')
      ? (_openBlock(), _createBlock(_component_NavTitleLivecastIcon, { key: 1 }))
      : (_openBlock(), _createBlock(_component_NavTitleLiveconfIcon, { key: 2 }))
}