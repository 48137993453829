import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3114464"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CliveLogoLink = _resolveComponent("CliveLogoLink")!
  const _component_GnbList = _resolveComponent("GnbList")!
  const _component_ConsoleLink = _resolveComponent("ConsoleLink")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", { 'type-feature': _ctx.isFeatureEnabled }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navClose && _ctx.navClose(...args)))
      }, [
        _createVNode(_component_CliveLogoLink, {
          class: "clive-logo",
          isMobileSize: false
        })
      ]),
      _createVNode(_component_GnbList),
      _createVNode(_component_ConsoleLink, { onClick: _ctx.trackEventConsole }, null, 8, ["onClick"])
    ])
  ], 2))
}