interface ActionSpec {
  type?: string;
  name?: string;
  kind?: string;
  copy?: string;
}

interface TrackerParams {
  [selector: string]: ActionSpec;
}

export const tracker: TrackerParams = {
  "첫 페이지": {
    type: "page_view",
    name: "첫 페이지",
    kind: "view_content"
  },
  "상품 Call 페이지": {
    type: "page_view",
    name: "상품 Call 페이지",
    kind: "view_content"
  },
  "상품 Cast 페이지": {
    type: "page_view",
    name: "상품 Cast 페이지",
    kind: "view_content"
  },
  "상품 Conference 페이지": {
    type: "page_view",
    name: "상품 Conference 페이지",
    kind: "view_content"
  },
  "개발가이드 페이지": {
    type: "page_view",
    name: "개발가이드 페이지",
    kind: "view_content"
  },
  "요금제 페이지": {
    type: "page_view",
    name: "요금제 페이지",
    kind: "view_content"
  },
  "login-console": {
    type: "event",
    name: "login-console",
    kind: "click_content"
  },
  "join-main": {
    type: "event",
    name: "join-main",
    kind: "click_content"
  },
  "join-footer": {
    type: "event",
    name: "join-footer",
    kind: "click_content"
  },
  "download-main-introductionpdf": {
    type: "event",
    name: "download-main-introductionpdf",
    kind: "click_content"
  },
  "ask-call-footer": {
    type: "event",
    name: "ask-call-footer",
    kind: "click_content"
  },
  "ask-cast-footer": {
    type: "event",
    name: "ask-cast-footer",
    kind: "click_content"
  },
  "ask-conference-footer": {
    type: "event",
    name: "ask-conference-footer",
    kind: "click_content"
  },
  "go-documents": {
    type: "event",
    name: "go-documents",
    kind: "click_content"
  },
  "go-community": {
    type: "event",
    name: "go-community",
    kind: "click_content"
  },
  "go-sampleapp": {
    type: "event",
    name: "go-sampleapp",
    kind: "click_content"
  },
  "go-github": {
    type: "event",
    name: "go-github",
    kind: "click_content"
  },
  "go-blog": {
    type: "event",
    name: "go-blog",
    kind: "click_content"
  },
  "ask-email": {
    type: "event",
    name: "ask-email",
    kind: "click_content"
  },
  "ask-pricing": {
    type: "event",
    name: "ask-pricing",
    kind: "click_content"
  },
  "ask-pricing-footer": {
    type: "event",
    name: "ask-pricing-footer",
    kind: "click_content"
  },
  "go-call-spec": {
    type: "event",
    name: "go-call-spec",
    kind: "click_content"
  },
  "go-cast-spec": {
    type: "event",
    name: "go-cast-spec",
    kind: "click_content"
  },
  "go-conference-spec": {
    type: "event",
    name: "go-conference-spec",
    kind: "click_content"
  }
};

export const trackerCopy: TrackerParams = {
  "login-console": {
    copy: "Console"
  },
  "join-main": {
    copy: "회원가입"
  },
  "join-footer": {
    copy: "회원가입"
  },
  "download-main-introductionpdf": {
    copy: "상품소개서"
  },
  "ask-call-footer": {
    copy: "문의하기"
  },
  "ask-cast-footer": {
    copy: "문의하기"
  },
  "ask-conference-footer": {
    copy: "문의하기"
  },
  "go-documents": {
    copy: "바로가기"
  },
  "go-community": {
    copy: "바로가기"
  },
  "go-sampleapp": {
    copy: "바로가기"
  },
  "go-github": {
    copy: "Github 바로가기"
  },
  "go-blog": {
    copy: "(구)리모트몬스터 블로그입니다"
  },
  "ask-email": {
    copy: "빠르게 답변드리겠습니다"
  },
  "ask-pricing": {
    copy: "문의하기"
  },
  "ask-pricing-footer": {
    copy: "문의하기"
  },
  "go-call-spec": {
    copy: "상세스펙 확인하기"
  },
  "go-cast-spec": {
    copy: "상세스펙 확인하기"
  },
  "go-conference-spec": {
    copy: "상세스펙 확인하기"
  }
};
