import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66231cdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxToggleButton = _resolveComponent("BoxToggleButton")!
  const _component_RelationList = _resolveComponent("RelationList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BoxToggleButton, {
      isActive: _ctx.isActive,
      onClick: _ctx.toggleList
    }, null, 8, ["isActive", "onClick"]),
    _withDirectives(_createVNode(_component_RelationList, {
      "aria-hidden": !_ctx.isActive
    }, null, 8, ["aria-hidden"]), [
      [_vShow, _ctx.isActive]
    ])
  ]))
}