import "@/assets/styles/app.scss";
import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import camelCase from "lodash/camelCase";
import upperCase from "lodash/upperCase";
import VueGtag from "vue-gtag-next";
import mitt from 'mitt';

const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const app = createApp(App);
const head = createHead();

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperCase(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  app.component(componentName, componentConfig.default || componentConfig);
});

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app
  .use(store)
  .use(router)
  .use(head)
  .use(VueGtag)
  .mount("#app");
