<template>
  <AppLink
    class="link"
    :class="{ 'is-active': isVisible }"
    href="#"
    role="button"
    text="회사정보"
    :hiddenText="isVisible ? '메뉴 닫기' : '메뉴 열기'"
  />
</template>

<script lnag="ts">
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    AppLink
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/utils/_mixin_media";
@import "@/assets/styles/utils/_mixin_bg";
@import "@/assets/styles/utils/_sprite_common";
@import "@/assets/styles/utils/_sprite_footer_mobile";
@import "@/assets/styles/base/_var_color";
.link {
  display: flex;
  align-items: center;
  color: $base-gray;

  &:after {
    content: "";
    margin-left: 4px;
    @extend .sp-footer-m;
    @extend %sp-footer-ico-toggle-arrow;
    @extend %sp-footer-ico-toggle-arrow-top;
  }

  &:hover:after {
    @extend %sp-footer-ico-toggle-arrow-top-on;
  }

  &.is-active {
    color: $base-black;
    &:hover:after,
    &:after {
      @extend %sp-footer-ico-toggle-arrow-bottom-on;
    }
  }
}
</style>
