export const RELATION_LIST = [
  {
    title: "서비스",
    categories: [
      {
        text: "카카오워크",
        url: "https://www.kakaowork.com/"
      },
      {
        text: "카카오 i 클라우드",
        url: "https://www.kakaoicloud.com/"
      },
      {
        text: "헤이카카오",
        url: "https://kakao.ai/"
      },
      {
        text: "카카오 i 라스",
        url: "#"
      },
      {
        text: "커넥트 라이브",
        url: "https://connectlive.kakaoi.ai/"
      }
    ]
  },
  {
    title: "기술",
    categories: [
      {
        text: "기술블로그",
        url: "https://tech.kakaoenterprise.com/"
      },
      {
        text: "AI 기술데모",
        url: "#"
      },
      {
        text: "AI 논문",
        url: "#"
      },
      {
        text: "기술문서",
        url: "https://docs.kakaoi.ai/"
      }
    ]
  },
  {
    title: "관계사",
    categories: [
      {
        text: "카카오",
        url: "https://www.kakao.com/"
      }
    ]
  }
];
