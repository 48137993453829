/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEVICE } from "../mutation-types";
import { MEDIA_VIEWPORT } from "@/shared/mediaViewport";
interface State {
  currentSize: number;
  isMobileAgent: boolean;
  isMediaScreen: boolean;
}

const state = {
  currentSize: 0,
  isMobileAgent: false,
  isMediaScreen: false
};

const getters = {
  deviceInfo: (state: State) => {
    return state.currentSize;
  },
  agentInfo: (state: State) => {
    return state.isMobileAgent;
  },
  screenInfo: (state: State) => {
    return state.isMediaScreen;
  }
};

const actions = {
  loadSizeCheck({ commit }: any) {
    commit(DEVICE.LOAD_VIEWPORT);
  },
  currentSizeCheck({ commit }: any, size: number) {
    commit(DEVICE.CURRENT_VIEWPORT, size);
  },
  agentCheck({ commit }: any, isMobileAgent: boolean) {
    commit(DEVICE.USER_AGENT, isMobileAgent);
  },
  largeScreenCheck({ commit }: any, size: number) {
    commit(DEVICE.LARGE_SCREEN, size);
  }
};

const mutations = {
  [DEVICE.LOAD_VIEWPORT](state: State) {
    state.currentSize = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
  },
  [DEVICE.CURRENT_VIEWPORT](state: State, size: number) {
    state.currentSize = size;
  },
  [DEVICE.USER_AGENT](state: State, isMobileAgent: boolean) {
    state.isMobileAgent = isMobileAgent;
  },
  [DEVICE.LARGE_SCREEN](state: State, size: number) {
    state.isMediaScreen = MEDIA_VIEWPORT.largeScreen >= size;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
