import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLink = _resolveComponent("AppLink")!

  return (_openBlock(), _createElementBlock("ul", null, [
    _createElementVNode("li", null, [
      _createVNode(_component_AppLink, {
        text: "Console",
        href: "https://console.connectlive.kakaoi.ai/",
        target: "_blank",
        class: "link"
      })
    ])
  ]))
}