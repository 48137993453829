import { VertebraTracker } from "@kep/vertebra-sdk";
import { tracker, trackerCopy } from "@/shared/trackParams";
import { krCurrentTime } from "./krCurrentDate";

export const trackerSetAction = (name: string) => {
  // @TODO: sdk 1.1.1 대기 - 배포 전 까지 deployment 강제 주입 할 것
  // trackerCommon.setAction({ ...tracker[name] }).track();
  VertebraTracker.setCommon({
    service_name: process.env.VUE_APP_VERTEBRA_SERVICE_NAME,
    deployment: process.env.VUE_APP_DEPLOYMENT as any,
    access_timestamp: krCurrentTime
  })
    .setAction({ ...tracker[name] })
    .track();
};

export const trackerSetClick = (name: string) => {
  VertebraTracker.setCommon({
    service_name: process.env.VUE_APP_VERTEBRA_SERVICE_NAME,
    deployment: process.env.VUE_APP_DEPLOYMENT as any,
    access_timestamp: krCurrentTime
  })
    .setAction({ ...tracker[name] })
    .setClick({ ...trackerCopy[name] })
    .track();
};

// @TODO: sdk 1.1.1 대기 / data-vertebra-* 프로퍼티 방식 전환시 사용
// export const trackerSetAutoClick = VertebraTracker.setCommon({
//   service_name: process.env.VUE_APP_VERTEBRA_SERVICE_NAME,
//   deployment: process.env.VUE_APP_DEPLOYMENT,
//   access_timestamp: krCurrentTime
// }).setAutoClick(true);
