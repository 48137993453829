
import { defineComponent } from "vue";
import { POLICY_LIST as list } from "@/shared/policyList";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
// import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
import ToggleListItem from "@/components/molecules/element/ToggleListItem/ToggleListItem.vue";
export default defineComponent({
  name: "PolicyLinkList",
  components: {
    // AppRouterLink,
    AppLink,
    ToggleListItem
  },
  setup() {
    const items = list;
    return {
      items
    };
  }
});
