
import { defineComponent } from "vue";
import NavMenuIcon from "@/components/atoms/icon/NavMenuIcon/NavMenuIcon.vue";
import AppButton from "@/components/atoms/button/AppButton/AppButton.vue";
import NavCloseIcon from "../../icon/NavCloseIcon/NavCloseIcon.vue";
export default defineComponent({
  components: { NavMenuIcon, AppButton, NavCloseIcon },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
});
