import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { trackRouter } from "vue-gtag-next";
const Main = () => import("@/pages/Main/Main.vue");
const MainMobile = () => import("@/pages/Main/MainMobile.vue");
const Guide = () => import("@/pages/Guide/Guide.vue");
const GuideMobile = () => import("@/pages/Guide/GuideMobile.vue");
const Products = () =>
  import(
    /* webpackChunkName: "group-products" */ "@/pages/Products/Products.vue"
  );
const ProductsMobile = () =>
  import(
    /* webpackChunkName: "group-products-mobile" */ "@/pages/Products/ProductsMobile.vue"
  );
const ProductLiveCall = () =>
  import(
    /* webpackChunkName: "group-products" */ "@/pages/Products/ProductLiveCall/ProductLiveCall.vue"
  );
const ProductLiveCallMobile = () =>
  import(
    /* webpackChunkName: "group-products-mobile" */ "@/pages/Products/ProductLiveCall/ProductLiveCallMobile.vue"
  );
const ProductLiveCast = () =>
  import(
    /* webpackChunkName: "group-products" */ "@/pages/Products/ProductLiveCast/ProductLiveCast.vue"
  );
const ProductLiveCastMobile = () =>
  import(
    /* webpackChunkName: "group-products-mobile" */ "@/pages/Products/ProductLiveCast/ProductLiveCastMobile.vue"
  );
const ProductLiveConference = () =>
  import(
    /* webpackChunkName: "group-products" */ "@/pages/Products/ProductLiveConference/ProductLiveConference.vue"
  );
const ProductLiveConferenceMobile = () =>
  import(
    /* webpackChunkName: "group-products-mobile" */ "@/pages/Products/ProductLiveConference/ProductLiveConferenceMobile.vue"
  );
const Pricing = () =>
  import(/* webpackChunkName: "group-pricing" */ "@/pages/Pricing/Pricing.vue");
const PricingMobile = () =>
  import(
    /* webpackChunkName: "group-pricing-mobile" */ "@/pages/Pricing/PricingMobile.vue"
  );
const PricingMainMobile = () =>
  import(
    /* webpackChunkName: "group-pricing-mobile-main" */ "@/pages/Pricing/PricingMainMobile.vue"
  );
const PricingDeveloperMobile = () =>
  import(
    /* webpackChunkName: "group-pricing-mobile-developer" */ "@/pages/Pricing/PricingDeveloper/PricingDeveloperMobile.vue"
  );
const PricingStartupMobile = () =>
  import(
    /* webpackChunkName: "group-pricing-mobile-startup" */ "@/pages/Pricing/PricingStartup/PricingStartupMobile.vue"
  );
const PricingBasicMobile = () =>
  import(
    /* webpackChunkName: "group-pricing-mobile-basic" */ "@/pages/Pricing/PricingBasic/PricingBasicMobile.vue"
  );
const PricingPremiumMobile = () =>
  import(
    /* webpackChunkName: "group-pricing-mobile-premium" */ "@/pages/Pricing/PricingPremium/PricingPremiumMobile.vue"
  );
const Policy = () =>
  import(/* webpackChunkName: "group-policy" */ "@/pages/Policy/Policy.vue");
const PolicyMobile = () =>
  import(
    /* webpackChunkName: "group-policy-mobile" */ "@/pages/Policy/PolicyMobile.vue"
  );
const Privacy = () =>
  import(
    /* webpackChunkName: "group-policy" */ "@/pages/Policy/Privacy/Privacy.vue"
  );
const PrivacyMobile = () =>
  import(
    /* webpackChunkName: "group-policy-mobile" */ "@/pages/Policy/Privacy/PrivacyMobile.vue"
  );
const Terms = () =>
  import(
    /* webpackChunkName: "group-policy" */ "@/pages/Policy/Terms/Terms.vue"
  );
const TermsMobile = () =>
  import(
    /* webpackChunkName: "group-policy-mobile" */ "@/pages/Policy/Terms/TermsMobile.vue"
  );
const NotFound = () => import("@/pages/NotFound/NotFound.vue");
const NotFoundMobile = () => import("@/pages/NotFound/NotFoundMobile.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Root",
    components: {
      default: Main,
      mobile: MainMobile
    }
  },
  {
    path: "/products",
    name: "Products",
    components: {
      default: Products,
      mobile: ProductsMobile
    },
    children: [
      {
        path: "livecall",
        name: "ProductLiveCall",
        components: {
          default: ProductLiveCall,
          mobile: ProductLiveCallMobile
        }
      },
      {
        path: "livecast",
        name: "ProductLiveCast",
        components: {
          default: ProductLiveCast,
          mobile: ProductLiveCastMobile
        }
      },
      {
        path: "liveconference",
        name: "ProductLiveConference",
        components: {
          default: ProductLiveConference,
          mobile: ProductLiveConferenceMobile
        }
      }
    ]
  },
  {
    path: "/solutions",
    name: "Solutions",
    components: {
      default: () => import("@/pages/Solutions/Solutions.vue"),
      mobile: () => import("@/pages/Solutions/SolutionsMobile.vue")
    },
    redirect: "/solutions/education",
    children: [
      {
        path: "education",
        name: "SolutionEducation",
        components: {
          default: () =>
            import("@/pages/Solutions/SolutionEducation/SolutionEducation.vue"),
          mobile: () =>
            import(
              "@/pages/Solutions/SolutionEducation/SolutionEducationMobile.vue"
            )
        }
      },
      {
        path: "media",
        name: "SolutionMedia",
        components: {
          default: () =>
            import("@/pages/Solutions/SolutionMedia/SolutionMedia.vue"),
          mobile: () =>
            import("@/pages/Solutions/SolutionMedia/SolutionMediaMobile.vue")
        }
      },
      {
        path: "enterprise",
        name: "SolutionEnterprise",
        components: {
          default: () =>
            import(
              "@/pages/Solutions/SolutionEnterprise/SolutionEnterprise.vue"
            ),
          mobile: () =>
            import(
              "@/pages/Solutions/SolutionEnterprise/SolutionEnterpriseMobile.vue"
            )
        }
      },
      {
        path: "commerce",
        name: "SolutionCommerce",
        components: {
          default: () =>
            import("@/pages/Solutions/SolutionCommerce/SolutionCommerce.vue"),
          mobile: () =>
            import(
              "@/pages/Solutions/SolutionCommerce/SolutionCommerceMobile.vue"
            )
        }
      },
      {
        path: "healthcare",
        name: "SolutionHealthcare",
        components: {
          default: () =>
            import(
              "@/pages/Solutions/SolutionHealthcare/SolutionHealthcare.vue"
            ),
          mobile: () =>
            import(
              "@/pages/Solutions/SolutionHealthcare/SolutionHealthcareMobile.vue"
            )
        }
      }
    ]
  },
  {
    path: "/pricing",
    name: "Pricing",
    components: {
      default: Pricing,
      mobile: PricingMobile
    },
    children: [
      {
        path: "",
        name: "PricingMain",
        components: {
          default: PricingMainMobile,
          mobile: PricingMainMobile
        }
      },
      {
        path: "developer",
        name: "PricingDeveloper",
        components: {
          default: PricingDeveloperMobile,
          mobile: PricingDeveloperMobile
        }
      },
      {
        path: "startup",
        name: "PricingStartup",
        components: {
          default: PricingStartupMobile,
          mobile: PricingStartupMobile
        }
      },
      {
        path: "basic",
        name: "PricingBasic",
        components: {
          default: PricingBasicMobile,
          mobile: PricingBasicMobile
        }
      },
      {
        path: "premium",
        name: "PricingPremium",
        components: {
          default: PricingPremiumMobile,
          mobile: PricingPremiumMobile
        }
      }
    ]
  },
  {
    path: "/guide",
    name: "Guide",
    components: {
      default: Guide,
      mobile: GuideMobile
    }
  },
  {
    path: "/policy",
    name: "Policy",
    components: {
      default: Policy,
      mobile: PolicyMobile
    },
    redirect: "/policy/privacy",
    children: [
      {
        path: "privacy",
        name: "Privacy",
        components: {
          default: Privacy,
          mobile: PrivacyMobile
        }
      },
      {
        path: "privacy-v1",
        name: "PrivacyV1",
        components: {
          default: () => import("@/pages/Policy/Privacy/Archive/PrivacyV1.vue"),
          mobile: () =>
            import("@/pages/Policy/Privacy/Archive/PrivacyMobileV1.vue")
        }
      },
      {
        path: "terms",
        name: "Terms",
        components: {
          default: Terms,
          mobile: TermsMobile
        }
      }
    ]
  },
  {
    path: "/selftest",
    name: "selftest",
    components: {
      default: () => import("@/pages/SelfRtcTest/SelfRtcTest.vue"),
      mobile: () => import("@/pages/SelfRtcTest/SelfRtcTest.vue")
    }
  },
  {
    path: "/inquiry",
    name: "Inquiry",
    components: {
      default: () => import("@/pages/Inquiry/Inquiry.vue"),
      mobile: () => import("@/pages/Inquiry/Inquiry.vue")
    }
  },
  {
    path: "/inquiry/tech",
    name: "InquiryTech",
    components: {
      default: () => import("@/pages/Inquiry/tech/InquiryTech.vue"),
      mobile: () => import("@/pages/Inquiry/tech/InquiryTech.vue")
    }
  },
  {
    path: "/inquiry/success",
    name: "InquirySuccess",
    components: {
      default: () =>
        import("@/pages/Inquiry/InquirySuccess/InquirySuccess.vue"),
      mobile: () =>
        import("@/pages/Inquiry/InquirySuccess/InquirySuccessMobile.vue")
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    components: {
      notfound: NotFound,
      notfoundMobile: NotFoundMobile
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

trackRouter(router);

export default router;
