
import { defineComponent, ref } from "vue";
import { ROUTER_PATH as list } from "@/shared/routerPathMobile";
import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import NavArrowIcon from "@/components/atoms/icon/NavArrowIcon/NavArrowIcon.vue";
import NavTitleIcon from "@/components/atoms/icon/NavTitleIcon/NavTitleIcon.vue";
export default defineComponent({
  name: "MobileNavList",
  components: {
    AppRouterLink,
    AppLink,
    NavArrowIcon,
    NavTitleIcon
  },
  props: {
    isListVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["navClose", "listToggle"],
  setup() {
    const items = list;
    const isPriceListShow = ref(false);
    const isSolutionListShow = ref(false);

    const toggleList = (menu: string) => {
      switch (menu.toLowerCase()) {
        case "solutions":
          isSolutionListShow.value = !isSolutionListShow.value;
          break;
        case "pricing":
        isPriceListShow.value = !isPriceListShow.value;
          break;
        default:
          break;
      }

      // this.$emit("navClose");
    };

    return {
      items,
      isPriceListShow,
      isSolutionListShow,
      toggleList
    };
  }
});
