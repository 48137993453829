
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import { defineComponent } from "vue";
import { RELATION_LIST as list } from "@/shared/relationList";
export default defineComponent({
  components: { AppLink },
  setup() {
    const items = list;
    return {
      items
    };
  }
});
