
  import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";

  export default {
    name: "closeModal",
    setup() {
      const internalInstance = getCurrentInstance();
      const emitter = internalInstance?.appContext.config.globalProperties.emitter;

      const isModalOpen = ref(false);
      const isOneDayChecked = ref(false);
      const isBannerClicked = ref(false);

      const getCookie = (name: string) => {
        const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? value[2] : null;
      };

      const openModal = (bannerClicked = false) => {
        isOneDayChecked.value = false;
        isModalOpen.value = true;
        isBannerClicked.value = bannerClicked;
        toggleBodyClass('addClass', 'modal--open');
      };

      const closeModal = () => {
        isModalOpen.value = false;
        toggleBodyClass('removeClass', 'modal--open');
      };

      const closeModalDuring1Year = () => {
        isOneDayChecked.value = true;
        const todayDate = new Date();
        todayDate.setDate( todayDate.getDate() + 365 );

        const stringDate = todayDate.toUTCString();
        document.cookie = `modalClose=CLOSED; expires=${stringDate}`
        closeModal();
      }

      const toggleBodyClass = (addRemoveClass: "addClass" | "removeClass", className: string) => {
        const el = document.body;

        if (addRemoveClass === 'addClass') {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      }

      onMounted(() => {
        emitter.on("onOpenModalEvent", openModal);
      });

      onUnmounted(() => {
        emitter.off("onOpenModalEvent", openModal);
        toggleBodyClass('removeClass', 'modal--open');
      });

      return {
        isModalOpen,
        closeModal,
        closeModalDuring1Year,
        toggleBodyClass,
        isOneDayChecked
      };
    },
  }

