import { useGtag } from "vue-gtag-next";
const { event } = useGtag();

interface GtagEventsParams {
  [selector: string]: {
    [selector: string]: string;
  };
}
/* eslint-disable */
const gtagTrack: GtagEventsParams = {
  console: {
    event_category: "login",
    event_label: "웹 콘솔로 이동"
  },
  "main-무료로 시작하기": {
    event_category: "join",
    event_label: "main '무료로 시작하기' 버튼 클릭"
  },
  "main-도입문의": {
    event_category: "ask",
    event_label: "main '도입문의' 버튼 클릭"
  },
  "Live Call 더 알아보기": {
    event_category: "go",
    event_label: "main 'Call 더 알아보기' 버튼 클릭"
  },
  "Live Cast 더 알아보기": {
    event_category: "go",
    event_label: "main 'Cast 더 알아보기' 버튼 클릭"
  },
  "Live Conference 더 알아보기": {
    event_category: "go",
    event_label: "main 'Conference 더 알아보기' 버튼 클릭"
  },
  "문의 등록하기": {
    event_category: "ask",
    event_label: "도입문의 '문의 등록하기' 버튼 클릭"
  },
  "main-회원가입": {
    event_category: "join",
    event_label: "main 회원가입 버튼 클릭"
  },
  "footer-회원가입": {
    event_category: "join",
    event_label: "footer 회원가입 버튼 클릭"
  },
  상품소개서: {
    event_category: "download",
    event_label: "상품소개서 다운로드"
  },
  "call-문의하기-footer": {
    event_category: "ask",
    event_label: "상품 Call 문의하기 (하단)"
  },
  "cast-문의하기-footer": {
    event_category: "ask",
    event_label: "상품 Cast 문의하기 (하단)"
  },
  "conference-문의하기-footer": {
    event_category: "ask",
    event_label: "상품 Conference 문의하기 (하단)"
  },
  "documents 바로가기": {
    event_category: "go",
    event_label: "개발가이드 문서 바로가기"
  },
  "community 바로가기": {
    event_category: "go",
    event_label: "개발가이드 커뮤니티 바로가기"
  },
  "sampleapp 바로가기": {
    event_category: "go",
    event_label: "개발가이드 샘플앱 바로가기"
  },
  "github 바로가기": {
    event_category: "go",
    event_label: "개발가이드 깃헙 바로가기"
  },
  "커뮤니티 바로가기": {
    event_category: "go",
    event_label: "개발가이드 커뮤니티 바로가기 (하단)"
  },
  "blog 바로가기": {
    event_category: "go",
    event_label: "개발가이드 블로그 바로가기"
  },
  "email 문의하기": {
    event_category: "ask",
    event_label: "개발가이드 이메일 문의하기"
  },
  "요금제 문의하기": {
    event_category: "ask",
    event_label: "요금제 문의하기 (상단)"
  },
  "요금제-문의하기-footer": {
    event_category: "ask",
    event_label: "요금제 문의하기 (하단)"
  },
  "call-상세스펙": {
    event_category: "go",
    event_label: "상품 Call 상세스펙 확인 버튼 클릭"
  },
  "cast-상세스펙": {
    event_category: "go",
    event_label: "상품 Cast 상세스펙 확인 버튼 클릭"
  },
  "conference-상세스펙": {
    event_category: "go",
    event_label: "상품 Conference 상세스펙 확인 버튼 클릭"
  }
};

export const gtagEvents = (name: string) => event(name, gtagTrack[name]);
