import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea68d4ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLink = _resolveComponent("AppLink")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "toggle-list",
    "aria-hidden": !_ctx.isVisible
  }, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createVNode(_component_AppLink, {
            class: "link",
            href: item.url,
            text: item.text,
            target: _ctx.isLastItem(index, _ctx.items.length) ? null : '_blank',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickEvent', _ctx.isVisible)))
          }, null, 8, ["href", "text", "target"])
        ]))
      }), 128))
    ])
  ], 8, _hoisted_1)), [
    [_vShow, _ctx.isVisible]
  ])
}