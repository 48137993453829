import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78968728"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relation-wrap" }
const _hoisted_2 = {
  class: "list-relation",
  "aria-label": "관련사이트"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "list-sub" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLink = _resolveComponent("AppLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("strong", _hoisted_3, _toDisplayString(item.title), 1),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.categories, (val, idx) => {
              return (_openBlock(), _createElementBlock("li", { key: idx }, [
                _createVNode(_component_AppLink, {
                  href: val.url,
                  text: val.text,
                  class: "link",
                  target: "_blank"
                }, null, 8, ["href", "text"])
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}