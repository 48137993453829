<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="2" fill="#333333" />
    <rect y="7" width="20" height="2" fill="#333333" />
    <rect y="14" width="20" height="2" fill="#333333" />
  </svg>
</template>
