
import { computed, defineComponent, onMounted } from "vue";
// RouteMeta
import { useRoute, RouteLocationNormalizedLoaded } from "vue-router";
import { useStore } from "vuex";
import { checkMobile } from "@/service/checkMobile";
import { checkViewport } from "@/service/checkViewport";
import { checkScroll } from "@/service/checkScroll";
import AppHeader from "@/components/organisms/AppHeader/AppHeader.vue";
import AppHeaderMobile from "@/components/organisms/AppHeader/AppHeaderMobile.vue";
import AppFooter from "@/components/organisms/AppFooter/AppFooter.vue";
import AppFooterMobile from "@/components/organisms/AppFooter/AppFooterMobile.vue";
import ModalLayer from "@/components/templates/ModalLayer/ModalLayer.vue";
import ModalLayerMobile from "./components/templates/ModalLayer/ModalLayerMobile.vue";

export default defineComponent({
  components: {
    AppHeader,
    AppHeaderMobile,
    AppFooter,
    AppFooterMobile,
    ModalLayer,
    ModalLayerMobile
},
  setup() {
    const route: RouteLocationNormalizedLoaded = useRoute();
    const store = useStore();
    const pageFeatureInfo = computed(
      () => store.getters["pageCheck/pageFeatureInfo"]
    );
    const isMobileAgent = computed(
      () => store.getters["deviceCheck/agentInfo"]
    );
    const isNotfond = computed(
      () => "NotFound".indexOf(route.name as string) > -1
    );
    onMounted(() => {
      const htmlRoot = document.querySelector("html");
      if (isMobileAgent.value) htmlRoot?.classList.add("mobile");
    });
    return {
      store,
      pageFeatureInfo,
      isMobileAgent,
      isNotfond,
      ...checkMobile(),
      ...checkViewport(),
      ...checkScroll()
    };
  }
});
