
import CompanyList from "@/components/molecules/element/CompanyList/CompanyList.vue";
import ToggleItemTitle from "@/components/molecules/element/ToggleListTitle/ToggleListTitle.vue";
import { defineComponent, ref } from "vue";
export default defineComponent({
  components: {
    CompanyList,
    ToggleItemTitle
  },
  setup() {
    const isVisible = ref(false);
    const toggleList = () => {
      isVisible.value = !isVisible.value;
    };
    return {
      isVisible,
      toggleList
    };
  }
});
