<template>
  <AppLink href="https://www.kakaoenterprise.com/" target="_blank" class="link">
    <template v-slot:linkSlot>
      <CompanyLogo />
    </template>
  </AppLink>
</template>

<script>
import { defineComponent } from "vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import CompanyLogo from "@/components/atoms/logo/CompanyLogo/CompanyLogo.vue";
export default defineComponent({
  components: {
    AppLink,
    CompanyLogo
  }
});
</script>

<style lang="scss" scoped>
.link {
  display: block;
}
</style>
