import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavMenuIcon = _resolveComponent("NavMenuIcon")!
  const _component_NavCloseIcon = _resolveComponent("NavCloseIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createBlock(_component_AppButton, {
    class: "button",
    "aria-label": _ctx.isActive ? '메뉴 닫기' : '메뉴 열기'
  }, {
    buttonSlot: _withCtx(() => [
      _withDirectives(_createVNode(_component_NavMenuIcon, { class: "icon" }, null, 512), [
        [_vShow, !_ctx.isActive]
      ]),
      _withDirectives(_createVNode(_component_NavCloseIcon, { class: "icon" }, null, 512), [
        [_vShow, _ctx.isActive]
      ])
    ]),
    _: 1
  }, 8, ["aria-label"]))
}