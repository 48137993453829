import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "78",
  height: "36",
  viewBox: "0 0 78 36",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]
const _hoisted_7 = ["fill"]
const _hoisted_8 = ["fill"]
const _hoisted_9 = ["fill"]
const _hoisted_10 = ["fill"]
const _hoisted_11 = ["fill"]
const _hoisted_12 = ["fill"]
const _hoisted_13 = ["fill"]
const _hoisted_14 = ["fill"]
const _hoisted_15 = ["fill"]
const _hoisted_16 = ["fill"]
const _hoisted_17 = ["fill"]
const _hoisted_18 = ["fill"]
const _hoisted_19 = ["fill"]
const _hoisted_20 = ["fill"]
const _hoisted_21 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M10.3051 4.46652H9.48014C9.43064 4.46652 9.3894 4.50776 9.3894 4.55726V4.89549C8.94393 4.54901 8.39121 4.34277 7.789 4.34277C6.3536 4.34277 5.19043 5.50594 5.19043 6.94135C5.19043 8.37675 6.3536 9.53992 7.789 9.53992C8.39121 9.53992 8.94393 9.33369 9.3894 8.98721V9.32544C9.3894 9.37493 9.43064 9.41618 9.48014 9.41618H10.3051C10.3546 9.41618 10.3958 9.37493 10.3958 9.32544V4.55726C10.3958 4.50776 10.3546 4.46652 10.3051 4.46652ZM7.84675 8.47574C6.99706 8.47574 6.3041 7.78279 6.3041 6.9331C6.3041 6.08341 6.99706 5.39045 7.84675 5.39045C8.69644 5.39045 9.3894 6.08341 9.3894 6.9331C9.3894 7.78279 8.69644 8.47574 7.84675 8.47574Z"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M1.00014 2H0.092697C0.0425806 2 0.00195312 2.04063 0.00195312 2.09074V9.31726C0.00195312 9.36737 0.0425806 9.408 0.092697 9.408H1.00014C1.05025 9.408 1.09088 9.36737 1.09088 9.31726V2.09074C1.09088 2.04063 1.05025 2 1.00014 2Z"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M4.65476 4.52435C4.65476 4.52435 4.60526 4.47485 4.57226 4.47485H3.36784C3.36784 4.47485 3.31835 4.4831 3.30185 4.50785L1.31373 6.89194C1.31373 6.89194 1.28899 6.97443 1.31373 7.00743L3.30185 9.39152C3.30185 9.39152 3.3431 9.42452 3.36784 9.42452H4.57226C4.57226 9.42452 4.63826 9.40802 4.65476 9.37502C4.67126 9.34202 4.66301 9.30903 4.64651 9.28428L2.6254 6.95794L4.64651 4.63159C4.64651 4.63159 4.67126 4.5656 4.65476 4.5326V4.52435Z"
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M26.3261 4.34277C24.8907 4.34277 23.7275 5.50594 23.7275 6.94135C23.7275 8.37675 24.8907 9.53992 26.3261 9.53992C27.7615 9.53992 28.9247 8.37675 28.9247 6.94135C28.9247 5.50594 27.7615 4.34277 26.3261 4.34277ZM26.3261 8.4345C25.5012 8.4345 24.833 7.76629 24.833 6.94135C24.833 6.1164 25.5012 5.4482 26.3261 5.4482C27.1511 5.4482 27.8193 6.1164 27.8193 6.94135C27.8193 7.76629 27.1511 8.4345 26.3261 8.4345Z"
    }, null, 8, _hoisted_5),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M33.7097 3.5179C34.1469 3.5179 34.4686 3.19617 34.4686 2.7672C34.4686 2.33823 34.1304 2 33.7097 2C33.289 2 32.959 2.32998 32.959 2.7672C32.959 3.20442 33.2725 3.5179 33.7097 3.5179Z"
    }, null, 8, _hoisted_6),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M35.3096 8.41804H34.2537V4.5573C34.2537 4.5078 34.2125 4.46655 34.163 4.46655H32.1171C32.0676 4.46655 32.0264 4.5078 32.0264 4.5573V5.37399C32.0264 5.42349 32.0676 5.46474 32.1171 5.46474H33.173V8.41804H32.1171C32.0676 8.41804 32.0264 8.45928 32.0264 8.50878V9.32548C32.0264 9.37497 32.0676 9.41622 32.1171 9.41622H35.3096C35.3591 9.41622 35.4004 9.37497 35.4004 9.32548V8.50878C35.4004 8.45928 35.3591 8.41804 35.3096 8.41804Z"
    }, null, 8, _hoisted_7),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M16.6655 4.52435C16.6655 4.52435 16.616 4.47485 16.583 4.47485H15.3786C15.3786 4.47485 15.3291 4.4831 15.3126 4.50785L13.3245 6.89194C13.3245 6.89194 13.2997 6.97443 13.3245 7.00743L15.3126 9.39152C15.3126 9.39152 15.3538 9.42452 15.3786 9.42452H16.583C16.583 9.42452 16.649 9.40802 16.6655 9.37502C16.682 9.34202 16.6737 9.30903 16.6572 9.28428L14.6361 6.95794L16.6572 4.63159C16.6572 4.63159 16.682 4.5656 16.6655 4.5326V4.52435Z"
    }, null, 8, _hoisted_8),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M13.0109 2H12.1034C12.0533 2 12.0127 2.04063 12.0127 2.09074V9.31726C12.0127 9.36737 12.0533 9.408 12.1034 9.408H13.0109C13.061 9.408 13.1016 9.36737 13.1016 9.31726V2.09074C13.1016 2.04063 13.061 2 13.0109 2Z"
    }, null, 8, _hoisted_9),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M22.308 4.46652H21.4831C21.4336 4.46652 21.3923 4.50776 21.3923 4.55726V4.89549C20.9469 4.54901 20.3941 4.34277 19.7919 4.34277C18.3565 4.34277 17.1934 5.50594 17.1934 6.94135C17.1934 8.37675 18.3565 9.53992 19.7919 9.53992C20.3941 9.53992 20.9469 9.33369 21.3923 8.98721V9.32544C21.3923 9.37493 21.4336 9.41618 21.4831 9.41618H22.308C22.3575 9.41618 22.3988 9.37493 22.3988 9.32544V4.55726C22.3988 4.50776 22.3575 4.46652 22.308 4.46652ZM19.8497 8.47574C19 8.47574 18.307 7.78279 18.307 6.9331C18.307 6.08341 19 5.39045 19.8497 5.39045C20.6994 5.39045 21.3923 6.08341 21.3923 6.9331C21.3923 7.78279 20.6994 8.47574 19.8497 8.47574Z"
    }, null, 8, _hoisted_10),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M47.0234 4.34277C45.588 4.34277 44.4248 5.50594 44.4248 6.94135C44.4248 8.37675 45.588 9.53992 47.0234 9.53992C48.4588 9.53992 49.622 8.37675 49.622 6.94135C49.622 5.50594 48.4588 4.34277 47.0234 4.34277ZM47.0234 8.4345C46.1984 8.4345 45.5302 7.76629 45.5302 6.94135C45.5302 6.1164 46.1984 5.4482 47.0234 5.4482C47.8483 5.4482 48.5165 6.1164 48.5165 6.94135C48.5165 7.76629 47.8483 8.4345 47.0234 8.4345Z"
    }, null, 8, _hoisted_11),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M42.3458 7.66731C42.0488 8.19528 41.4466 8.5335 40.7702 8.40976C40.1927 8.30252 39.7142 7.8488 39.5905 7.27134C39.3843 6.30616 40.1185 5.45646 41.0506 5.45646C41.6116 5.45646 42.0901 5.76994 42.3458 6.22366C42.3623 6.25666 42.4118 6.28141 42.4448 6.26491L43.3522 6.00093C43.4017 5.98443 43.4347 5.92668 43.41 5.87718C42.9563 4.879 41.9003 4.21905 40.7042 4.37579C39.508 4.53253 38.6006 5.48121 38.4686 6.65263C38.2953 8.22003 39.5162 9.55644 41.0506 9.55644C42.0983 9.55644 43.0058 8.92948 43.4182 8.03029C43.443 7.98079 43.4182 7.92305 43.3605 7.90655L42.453 7.64257C42.453 7.64257 42.3705 7.64257 42.354 7.68381L42.3458 7.66731Z"
    }, null, 8, _hoisted_12),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M53.5074 4.34272C52.8145 4.34272 52.2865 4.6232 51.9483 5.06042V4.55721C51.9483 4.50771 51.9071 4.46646 51.8576 4.46646H50.9501C50.9006 4.46646 50.8594 4.50771 50.8594 4.55721V9.31714C50.8594 9.36663 50.9006 9.40788 50.9501 9.40788H51.8576C51.9071 9.40788 51.9483 9.36663 51.9483 9.31714V6.62782C51.9483 5.91837 52.4928 5.4069 53.2105 5.4069C53.9282 5.4069 54.4149 5.95961 54.4149 6.61957V9.31714C54.4149 9.36663 54.4561 9.40788 54.5056 9.40788H55.4131C55.4626 9.40788 55.5038 9.36663 55.5038 9.31714V6.30609C55.5038 5.24191 54.6954 4.33447 53.5074 4.33447V4.34272Z"
    }, null, 8, _hoisted_13),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M72.836 7.66731C72.5391 8.19528 71.9368 8.5335 71.2604 8.40976C70.6829 8.30252 70.2045 7.8488 70.0807 7.27134C69.8745 6.30616 70.6087 5.45646 71.5409 5.45646C72.1018 5.45646 72.5803 5.76994 72.836 6.22366C72.8525 6.25666 72.902 6.28141 72.935 6.26491L73.8425 6.00093C73.892 5.98443 73.925 5.92668 73.9002 5.87718C73.4465 4.879 72.3906 4.21905 71.1944 4.37579C69.9982 4.53253 69.0908 5.48121 68.9588 6.65263C68.7856 8.22003 70.0065 9.55644 71.5409 9.55644C72.5885 9.55644 73.496 8.92948 73.9085 8.03029C73.9332 7.98079 73.9085 7.92305 73.8507 7.90655L72.9433 7.64257C72.9433 7.64257 72.8608 7.64257 72.8443 7.68381L72.836 7.66731Z"
    }, null, 8, _hoisted_14),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M61.6669 6.31439C61.6669 5.25021 60.8585 4.34277 59.6705 4.34277C58.9776 4.34277 58.4496 4.62325 58.1114 5.06048V4.55726C58.1114 4.50776 58.0701 4.46652 58.0206 4.46652H57.1132C57.0637 4.46652 57.0225 4.50776 57.0225 4.55726V9.31719C57.0225 9.36668 57.0637 9.40793 57.1132 9.40793H58.0206C58.0701 9.40793 58.1114 9.36668 58.1114 9.31719V6.62787C58.1114 5.91842 58.6558 5.40695 59.3736 5.40695C60.0913 5.40695 60.578 5.95966 60.578 6.61962V9.31719C60.578 9.36668 60.6192 9.40793 60.6687 9.40793H61.5762C61.6256 9.40793 61.6669 9.36668 61.6669 9.31719V6.30614V6.31439Z"
    }, null, 8, _hoisted_15),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M67.6472 8.20346L66.7233 7.93123C66.7233 7.93123 66.6573 7.93123 66.6326 7.95598C66.3026 8.3272 65.8736 8.48394 65.3786 8.46744C64.7022 8.4427 64.067 7.99723 63.935 7.33727H67.8287C67.8782 7.33727 67.9112 7.30427 67.9195 7.25478C67.936 7.14753 67.9442 7.04029 67.9442 6.93305C67.9442 5.53064 66.9295 4.33447 65.3456 4.33447C64.034 4.33447 62.9698 5.28316 62.8213 6.57832C62.6233 8.26946 63.8855 9.53162 65.3951 9.53162C66.3768 9.53162 67.1935 9.13565 67.705 8.33545C67.738 8.28596 67.705 8.21171 67.6555 8.19521L67.6472 8.20346ZM65.3704 5.4069C66.0303 5.4069 66.6573 5.85237 66.781 6.47108H63.9597C64.0917 5.85237 64.7104 5.4069 65.3704 5.4069Z"
    }, null, 8, _hoisted_16),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M77.7693 5.44833C77.8188 5.44833 77.86 5.40709 77.86 5.35759V4.55739C77.86 4.5079 77.8188 4.46665 77.7693 4.46665H76.5814V3.59221C76.5814 3.54271 76.5401 3.50146 76.4906 3.50146H75.5832C75.5337 3.50146 75.4925 3.54271 75.4925 3.59221V4.46665H74.8407C74.7912 4.46665 74.75 4.5079 74.75 4.55739V5.35759C74.75 5.40709 74.7912 5.44833 74.8407 5.44833H75.4925V7.94791C75.4925 8.75636 76.1442 9.40807 76.9526 9.40807H77.761C77.8105 9.40807 77.8518 9.36682 77.8518 9.31732V8.51713C77.8518 8.46763 77.8105 8.42638 77.761 8.42638H77.1588C76.8371 8.42638 76.5731 8.1624 76.5731 7.84067V5.44833H77.761H77.7693Z"
    }, null, 8, _hoisted_17),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M2.55766 31.2645V18.1426H0V33.711H8.8962V31.2645H2.55766Z"
    }, null, 8, _hoisted_18),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M11.8398 20.9227C12.6849 20.9227 13.3744 20.211 13.3744 19.3881C13.3744 18.543 12.6849 17.8535 11.8398 17.8535C10.9946 17.8535 10.3052 18.543 10.3052 19.3881C10.3052 20.211 10.9946 20.9227 11.8398 20.9227ZM10.6388 33.711H13.0408V22.5907H10.6388V33.711Z"
    }, null, 8, _hoisted_19),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M23.6666 22.5907L20.5752 31.0421L17.4615 22.5907H14.8371L19.174 33.711H21.9541L26.291 22.5907H23.6666Z"
    }, null, 8, _hoisted_20),
    _createElementVNode("path", {
      fill: _ctx.color,
      d: "M28.9707 29.1739H37.8224C37.8669 28.8626 37.9114 28.5067 37.9114 28.1731C37.9114 24.9705 35.6428 22.3016 32.329 22.3016C28.8372 22.3016 26.4575 24.8593 26.4575 28.1509C26.4575 31.4869 28.8372 34.0001 32.5069 34.0001C34.6865 34.0001 36.3545 33.0883 37.3776 31.5759L35.3982 30.4194C34.8644 31.1978 33.8636 31.7983 32.5292 31.7983C30.7499 31.7983 29.3488 30.9087 28.9707 29.1739ZM28.9484 27.2168C29.282 25.5487 30.5053 24.4812 32.3068 24.4812C33.7524 24.4812 35.1535 25.3041 35.4871 27.2168H28.9484Z"
    }, null, 8, _hoisted_21)
  ]))
}