
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    type: {
      type: String,
      require: true,
      default: "button"
    },
    text: {
      type: String
    },
    hiddenText: {
      type: String
    }
  },
  emits: ["clickEvent"]
});
