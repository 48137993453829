
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
});
