export const ROUTER_PATH = [
  {
    name: "products",
    title: "상품",
    path: "/products"
  },
  {
    name: "solutions",
    title: "솔루션",
    path: "/solutions",
    children: [
      {
        name: "education",
        title: "교육",
        path: "/solutions/education"
      },
      {
        name: "media",
        title: "미디어&엔터테인먼트",
        path: "/solutions/media"
      },
      {
        name: "enterprise",
        title: "엔터프라이즈",
        path: "/solutions/enterprise"
      },
      {
        name: "commerce",
        title: "커머스",
        path: "/solutions/commerce"
      },
      {
        name: "healthcare",
        title: "헬스케어",
        path: "/solutions/healthcare"
      }
    ]
  },
  {
    name: "pricing",
    title: "요금제",
    path: "/pricing"
  },
  {
    name: "guide",
    title: "개발자센터",
    path: "/guide"
  },
  {
    name: "blog",
    title: "블로그",
    path: "https://blog.connectlive.kakaoi.ai/"
  }
];
