
import { defineComponent } from "vue";
export default defineComponent({
  name: "AppRouterLink",
  props: {
    url: {
      type: String,
      require: true,
      default: "/"
    },
    hiddenText: {
      type: String
    },
    text: {
      type: String
    },
    title: {
      type: String
    }
  }
});
