
import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
import { computed, defineComponent, ref } from "vue";
import { ROUTER_PATH as list } from "@/shared/routerPath";
import { useRoute } from "vue-router";
export default defineComponent({
  components: { AppRouterLink },
  setup() {
    const items = list;
    const route = useRoute();
    const fullPath = computed(() => route.path);
    const activeLink = ref("");
    const activeSubLink = ref("");
    const isNavBarActive = ref(false);

    const onHoverNavBar = (value: boolean) => {
      isNavBarActive.value = value;
    };

    const onHoverLink = (name: string) => {
      activeLink.value = name;
    };

    const onHoverSubLink = (name: string) => {
      activeSubLink.value === name
        ? (activeSubLink.value = "")
        : (activeSubLink.value = name);
    };

    // const onClickLink = (path: string) => {
    //   activeLink.value === path
    //     ? (activeLink.value = "")
    //     : (activeLink.value = path);
    // };
    return {
      items,
      fullPath,
      activeLink,
      activeSubLink,
      isNavBarActive,
      onHoverNavBar,
      onHoverLink,
      onHoverSubLink
      // onClickLink
    };
  }
});
