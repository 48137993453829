export const COMPANY_LIST = [
  {
    text: "회사소개",
    url: "https://www.kakaoenterprise.com/introduce/company"
  },
  {
    text: "언론보도",
    url: "https://www.kakaoenterprise.com/board/list/news"
  },
  {
    text: "새소식",
    url: "#"
  },
  {
    text: "인재영입",
    url: "#"
  }
  // {
  //   text: "회사소개서",
  //   url: "/files/ebook-2020.pdf"
  // }
];
