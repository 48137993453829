import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42e5feac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleListItemMobile = _resolveComponent("ToggleListItemMobile")!
  const _component_AppLink = _resolveComponent("AppLink")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_ToggleListItemMobile)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createVNode(_component_AppLink, {
          href: item.url,
          text: item.text,
          class: "link"
        }, null, 8, ["href", "text"])
      ]))
    }), 128))
  ]))
}