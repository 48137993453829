export const DEVICE = {
  USER_AGENT: "USER_AGENT",
  LOAD_VIEWPORT: "LOAD_VIEWPORT",
  CURRENT_VIEWPORT: "CURRENT_VIEWPORT",
  LARGE_SCREEN: "LARGE_SCREEN"
};

export const PAGING = {
  CURRENT_PAGE: "CUREENT_PAGE",
  FEATURE_ENABLED: "FEATURE_ENABLED",
  CURRENT_SCROLLING: "CURRENT_SCROLLING",
  CURRENT_OFFSET: "CURRENT_OFFSET"
};

export const PRICING_HEADER = {
  SET_FIXED: "SET_FIXED",
  SET_HIDE: "SET_HIDE"
};
