
import { defineComponent } from "vue";
import NavTitleLivecallIcon from "@/components/atoms/icon/NavTitleLivecallIcon/NavTitleLivecallIcon.vue";
import NavTitleLivecastIcon from "@/components/atoms/icon/NavTitleLivecastIcon/NavTitleLivecastIcon.vue";
import NavTitleLiveconfIcon from "@/components/atoms/icon/NavTitleLiveconfIcon/NavTitleLiveconfIcon.vue";
export default defineComponent({
  components: {
    NavTitleLivecallIcon,
    NavTitleLivecastIcon,
    NavTitleLiveconfIcon
  },
  props: {
    iconType: {
      type: String
    }
  }
});
