const SNS_LIST = [
  {
    name: "instagram",
    text: "인스타그램",
    url: "https://www.instagram.com/kakaoenterprise/"
  },
  {
    name: "facebook",
    text: "페이스북",
    url: "https://www.facebook.com/kakaoenterprise/"
  },
  {
    name: "blog",
    text: "블로그",
    url: "https://tech.kakaoenterprise.com/"
  },  
  {
    name: "youtube",
    text: "유튜브",
    url: "https://www.youtube.com/channel/UCVxXa4KKYyw3mQ3KYqze9Ng/featured"
  },
  {
    name: "ch",
    text: "카카오톡 채널",
    url: "https://pf.kakao.com/_lVmtT"
  }
];

export { SNS_LIST };
