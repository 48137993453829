
import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "AppLink",
  props: {
    hiddenText: {
      type: String
    },
    text: {
      type: String
    },
    title: {
      type: String
    },
    target: {
      type: String
    },
    href: {
      type: String
    },
    res: {
      type: String
    }
  },
  emits: ["clickEvent"],
  setup(props) {
    const isTargetBlank = computed(() => props.target === "_blank");
    const setRel = computed(() => (isTargetBlank.value ? "noopener" : null));
    return {
      isTargetBlank,
      setRel
    };
  }
});
